import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Test": "Test",
    }
  },
  fi: {
    translation: {
      "Not arrive": "Ei saapunut",
      "Incomplete": "Puutteellinen",
      "Arrived": "Saapunut",
      "Open": "Avoin",
      "In progress": "Käsittelyssä",
      "Complete": "Valmis",
      "Damaged": "Rikki",
      "Not paid": "Ei maksettu",
      "Company": "Yritys",
      "Supplier": "Tavarantoimittaja",
      "Partially paid": "Osittain maksettu",
      "Fully paid": "Kokonaan maksettu",
      "Draft": "Luonnos",
      "Open": "Avoin",
      "Confirmed": "Vahvistettu",
      "Paid": "Maksettu",
      "On delivery": "Matkalla",
      "Archived": "Arkistoitu",
      "New": "Uusi",
      "Customer order": "Asiakastilaus",
      "Complaint": "Reklamaatio",
      "Basic": "Perus",
      "Email does not match": "Sähköpostiosoite ei täsmää",
      "Passwords does not match": "Salasanat eivät täsmää",
      "Field is required": "Kenttä on pakollinen",
      "Check email format": "Tarkista sähköpostin muoto",
      "Password": "Salasana",
      "Password again": "Salasana uudelleen",
      "Register": "Rekisteröidy",
      "Forgot password": "Unohtuiko salasana",
      "Email": "Sähköposti",
      "Back to login": "Takaisin sisäänkirjautumiseen",
      "Wrong login password": "Väärä kirjautumis salasana",
      "new password does not match with confirm password": "Salasanat eivät ole samat"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fi",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;