import http from './api'

const url = '/events/'

export const httpGetEvents = (params) => {
  console.log("httpGetEvents");
  return http({
    url: `${url}` ,
    method: 'GET',
    params,
  })
}

export const httpGetEvent = (id) => {
  console.log("httpGetEvents");
  return http({
    url: `${url}${id}/` ,
    method: 'GET',
  })
}


export const httpAddEvent = (data) => {
  console.log("httpAddEvent");
  return http({
    url: `${url}` ,
    method: 'POST',
    data,
  })
}


export const httpUpdateEvent = (id, data) => {
  console.log("httpUpdateEvent");
  return http({
    url: `${url}${id}/` ,
    method: 'PATCH',
    data
  })
}

export const httpDeleteEvent = (id) => {
  console.log("httpDeleteEvents");
  return http({
    url: `${url}${id}/` ,
    method: 'DELETE',
  })
}
