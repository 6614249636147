// Base views
import Frontpage from "views/dashboard/frontpage/Frontpage";
import MyAccount from "views/dashboard/MyAccount/MyAccount";
import MyAccountPasswordChange from "views/dashboard/MyAccountPasswordChange/MyAccountPasswordChange";
import Profile from 'views/dashboard/ProfileView/TherapistProfile'
import MyAccountCalendar from 'views/dashboard/MyAccount/MyAccountCalendar'
import MyAccountPayment from 'views/dashboard/MyAccount/MyAccountPayment'
import MyAccountEvents from 'views/dashboard/MyAccount/MyAccountEvents'
import Therapists from 'views/dashboard/frontpage/Frontpage'

// Routes visible in left navbar
const navRoutes = [
  {
    path: "/frontpage",
    name: "Etusivu",
    icon: "fas fa-home text-primary",
    component: Frontpage,
    layout: "/dashboard",
  },
];

// all other usable routes
const hiddenRoutes = [
  {
    path: "/therapists/",
    name: "Terapeutit",
    icon: "ni ni-single-02 text-primary",
    component: Therapists,
    layout: "/dashboard",
    invisible: true,
    // permissions: 3,
  },
  {
    path: "/myaccount/password",
    name: "Omat tiedot salasananvaihto",
    icon: "fas fa-user text-primary",
    component: MyAccountPasswordChange,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/profile/:id",
    name: "Profiili näkymä",
    icon: "fas fa-user text-primary",
    component: Profile,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/myaccount/calendar",
    name: "Omat tiedot kalenteri",
    icon: "fas fa-user text-primary",
    component: MyAccountCalendar,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/myaccount/payment",
    name: "Omat tiedot maksuasetukset",
    icon: "fas fa-user text-primary",
    component: MyAccountPayment,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/myaccount/events",
    name: "Omat tiedot tapahtumat",
    icon: "fas fa-user text-primary",
    component: MyAccountEvents,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/myaccount",
    name: "Omat tiedot",
    icon: "fas fa-user text-primary",
    component: MyAccount,
    layout: "/dashboard",
    invisible: true,
  },
]

const routes = [
  ...navRoutes,
  ...hiddenRoutes,
]

export default routes