import http from './api'

export const httpGetExamples = (params) => {
  console.log("httpGetExamples");
  return http({
    url: `/examples/` ,
    method: 'GET',
    params,
  })
}

export const httpGetExample = (id) => {
  console.log("httpGetExample");
  return http({
    url: `/examples/${id}` ,
    method: 'GET',
  })
}