import React, { useCallback, useState } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

// core components
import { Input } from "stories/forms";
import { Button, Link, Typography } from "stories/components"
import { Card, CardBody, CardHeader, Col, Container, Row } from "stories/layout"
import { useNotification } from "stories/components/Notification"

import { httpSendPasswordResetConfirmation } from "services/users"

const PasswordForgot = (props) => {

  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
   } = props;
   
   const {
      history,
      t
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    history.push("/");
  }
  
  return (
    <>
      <Container className="mt-8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="7" className="">
            <Card variant="secondary">
              <CardHeader className="bg-transparent pt-5 pb-1">
                
                <div className="text-center text-muted mb-4">
                  <Typography variant="h2" className="mb-2">{t('Forgot password')}</Typography>
                  <Typography variant="p"></Typography>
                </div>
              
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("email")}
                      label={t("Email")}
                      type="email"
                      required
                    />
                  </Col>
                </Row>
                
                <div className="text-center text-muted mb-4">
                  <Button disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t("Lähetä")}</Button>
                  { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
                </div>
                
                <Row>
                  <Col className="mb-3 text-center">
                    <Typography variant="small"><Link to="/auth/login">{t("Back to login")}</Link></Typography>
                  </Col>
                </Row>
                
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}

const defaultValues = {
  email: '',
}

 const PasswordForgotFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      
      return Yup.object().shape({
        email: Yup.string().email(t('Tarkista sähköpostiosoitteen muoto')).required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      const data = {
        // ...values,
        email: values?.email,
      }
      httpSendPasswordResetConfirmation(data).then(res => {
        setSubmitting(false);
        props.handleResolve()
      }, error => {
        setSubmitting(false);
        setErrors({
          general: `${t('Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error)}`,
        })
      })
    },
    displayName: "BasicForm"
  
  })(PasswordForgot)
    
    
const PasswordForgotView = ({match, history}) => {
  // API requests here
  
  const { t } = useTranslation();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [infoAlert, setInfoAlert] = useState();
  
  const handleModalConfirm = () => {
    setInfoAlert(null);
    history.push("/dashboard/users");
  }
  
  const handleResolve = () => {
    notify({ title:t("Käyttäjä"), message:t("Salasanan palautuslinkki on lähetetty sähköpostiisi")})
    handleModalConfirm();
    /*
    setInfoAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Lähetetty"
        onConfirm={() => handleModalConfirm()}
        onCancel={() => handleModalConfirm()}
        btnSize=""
      >
        Kutsu lähetetty onnistuneesti
      </ReactBSAlert>
    )
    */
  }
  
  return (
    <>
      <PasswordForgotFormik history={history} t={t} handleResolve={handleResolve} notify={notify} />
      {infoAlert}
    </>
  )
}
  

export default PasswordForgotView;
