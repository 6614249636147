import React, { useState, useEffect } from "react";

import { httpGetEnums } from "services/enums";

import { Row, Col, Container } from "stories/layout";
import { Typography } from "stories/components";
import Select from "react-select";

const DropdownView = ({
  selectedTherapys,
  setSelectedTherapys,
  setSelectedTherapyTypes,
  setSelectedToWhos,
  setSelectedCity,
  setSelectedProvince,
  setRemoteAppointment,
}) => {
  const [therapyList, setTherapyList] = useState([]);
  const [therapyTypeList, setTherapyTypeList] = useState([]);
  const [toWhos, setToWhos] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [filteredTherapyTypes, setFilteredTherapyTypes] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    httpGetEnums().then((res) => {
      setTherapyList(res?.data.users.therapy);
      setTherapyTypeList(res?.data.users.therapy_type);
      setToWhos(res?.data.users.to_who);
      setCityList(
        res?.data.addresses.city.map((data) => {
          return {
            key: data.key,
            parent_id: data.parent_id,
            parent_value: data.parent_value,
            value: data.value,
            type: 1,
          };
        })
      );

      setProvinceList(
        res?.data.addresses.province.map((data) => {
          return {
            key: data.key,
            parent_id: data.parent_id,
            parent_value: data.parent_value,
            value: data.value,
            type: 2,
          };
        })
      );
      setLoading(false);
    });
  }, []);

  const remoteAppointmentChoise = [
    { value: "Etävastaanotto", key: "remote", type: 3 },
  ];

  useEffect(() => {
    if (selectedTherapys.length === 0 || selectedTherapys.value === "") {
      setFilteredTherapyTypes(therapyTypeList);
    } else {
      let list = [];
      list = [
        ...list,
        ...therapyTypeList.filter((s) =>
          s.therapies.map((the) => the.key).includes(selectedTherapys.key)
        ),
      ];
      list = [...new Set(list)];
      setFilteredTherapyTypes(list);
    }
  }, [selectedTherapys, therapyTypeList]);

  const therapyHandler = (event) => {
    if (!event) {
      event = {
        value: "",
      };
    }
    setSelectedTherapys(event);
  };

  const therapyTypesHandler = (event) => {
    if (!event) {
      event = {
        value: "",
      };
    }
    setSelectedTherapyTypes(event);
  };

  const toWhoHandler = (event) => {
    if (!event) {
      event = {
        value: "",
      };
    }
    setSelectedToWhos(event);
  };

  const locationHandler = (event) => {
    // console.log("event", event)
    if (!event || event === null) {
      event = {
        value: "",
      };
      setRemoteAppointment(false);
    }
    if (event.type === 2) {
      setRemoteAppointment(false);
      setSelectedProvince(event);
      setSelectedCity("");
      console.log("type 2");
    } else if (event.type === 1) {
      setRemoteAppointment(false);
      setSelectedProvince("");
      setSelectedCity(event);
      console.log("type 1");
    } else if (event.type === 3) {
      setRemoteAppointment(true);
      setSelectedProvince("");
      setSelectedCity("");
      console.log("type 3");
    } else {
      setRemoteAppointment(false);
      setSelectedProvince("");
      setSelectedCity("");
    }
  };

  const locationsOptions = [
    {
      options: remoteAppointmentChoise,
    },
    {
      label: "Maakunnat",
      options: provinceList?.sort((a, b) => a.value.localeCompare(b.value)),
    },
    {
      label: "Kaupungit",
      options: cityList?.sort((a, b) => a.value.localeCompare(b.value)),
    },
  ];

  // const studentHandler = (event) => {
  //   if (!event) {
  //     event = {
  //       value:''
  //     }
  //   }

  // }

  // const studentOptions = [
  //   {
  //     key: 1,
  //     value: 'Psykoterapeuttiopiskelija'
  //   },
  //   {
  //     key: 2,
  //     value: 'Lyhytterapeuttiopiskelija'
  //   }
  // ]

  return (
    <Container fluid className="bg-primary border-bottom p-4 navbar-top">
      <Row xs="1" sm="2" md="4" className="px-auto mx-2">
        <Col>
          <Typography className="mb-2" variant={"p"} bold={"bold"}>
            Valitse terapia
          </Typography>
          <Select
            isClearable={true}
            placeholder={loading ? "" : "Valitse terapia"}
            options={therapyList?.sort((a, b) =>
              a.value.localeCompare(b.value)
            )}
            getOptionLabel={(option) => `${option["value"]}`}
            getOptionValue={(option) => `${option["key"]}`}
            onChange={therapyHandler}
            isLoading={loading}
            isSearchable={!loading}
          />
        </Col>
        <Col>
          <Typography className="mb-2" variant={"p"} bold={"bold"}>
            Valitse muoto
          </Typography>
          <Select
            isClearable={true}
            placeholder={loading ? "" : "Valitse terapia muoto"}
            options={filteredTherapyTypes?.sort((a, b) =>
              a.value.localeCompare(b.value)
            )}
            getOptionLabel={(option) => `${option["value"]}`}
            getOptionValue={(option) => `${option["key"]}`}
            onChange={therapyTypesHandler}
            isLoading={loading}
            isSearchable={!loading}
          />
        </Col>
        <Col>
          <Typography className="mb-2" variant={"p"} bold={"bold"}>
            Valitse kenelle
          </Typography>
          <Select
            placeholder={loading ? "" : "Valitse kenelle"}
            isClearable={true}
            options={toWhos?.sort((a, b) => a.value.localeCompare(b.value))}
            getOptionLabel={(option) => `${option["value"]}`}
            getOptionValue={(option) => `${option["key"]}`}
            onChange={toWhoHandler}
            isLoading={loading}
            isSearchable={!loading}
          />
        </Col>
        <Col>
          <Typography className="mb-2" variant={"p"} bold={"bold"}>
            Valitse sijainti
          </Typography>
          <Select
            placeholder={loading ? "" : "Valitse sijainti"}
            isClearable={true}
            options={locationsOptions}
            getOptionLabel={(option) => `${option["value"]}`}
            getOptionValue={(option) => `${option["key"]}`}
            onChange={locationHandler}
            isLoading={loading}
            isSearchable={!loading}
          />
        </Col>
        {/* <Col>
          <Typography className="mb-2" variant={"p"} bold={'bold'} >Terapeuttiopiskelija</Typography>
          <Select
            placeholder={'Opiskelija muoto'} 
            isClearable={true}
            options={studentOptions} 
            getOptionLabel={(option) => `${option['value']}`}
            getOptionValue={(option) => `${option['key']}`}
            onChange={studentHandler}
          />
        </Col> */}
      </Row>
    </Container>
  );
};

export default DropdownView;
