import React, { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next'
import { withFormik } from 'formik'
import moment from "moment"
import fi from 'date-fns/locale/fi'

import { httpGetEvents } from "services/events"
import { useAuthContext }from "contexts/AuthContext"
import { useNotification } from "stories/components/Notification"

import { Button, Typography, Modal } from "stories/components"
import { Container, Row, Col } from "stories/layout"


import { registerLocale } from "react-datepicker"

import { Table, THead } from "stories/tables"

import LoaderScreen from "components/LoaderScreen"

import warning from 'assets/img/icons/common/circle-warning.svg'

import appConfig from "services/config"

const baseUrl = appConfig.apiUrl;

registerLocale('fi', fi)

const timeConvert = (start, end) => {
  if (moment(end).diff(moment(start), "minutes") === 60) {
    return "45min"
  } else {
    return "90min"
  }
}


const MyAccountEvents = (props) => {
  const {
    events,
    myUser,
  } = props

  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState()

  useEffect(() => {
    // console.log('events', events)
  },[events])
  
  const toggleModal = (id) => {
    setModalData(id)
    setModal(!modal)
  }

  const customerReceiptDownloader = (id) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `jwt ${localStorage.getItem('accessToken')}` 
      }
    }

    fetch(`${baseUrl}/events/${id}/get_receipt/`, requestOptions)
    .then((result) => {			
      if (result.status !== 200) { throw new Error("Bad server response"); }
      return result.blob();
      })

    .then((data) => {
      let url = window.URL.createObjectURL(data),
      anchor = document.createElement("a")
      anchor.href = url
      anchor.download = `${moment(events.find(event => event.id === id).start_time).format("HHmm_DD-MM-YYYY")}.pdf`
      anchor.click()
    })
  }

  const therapistReceiptDownloader = (id) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `jwt ${localStorage.getItem('accessToken')}` 
      }
    }

    fetch(`${baseUrl}/events/${id}/get_therapist_receipt/`, requestOptions)
    .then((result) => {			
      if (result.status !== 200) { throw new Error("Bad server response"); }
      return result.blob();
      })

    .then((data) => {
      let url = window.URL.createObjectURL(data),
      anchor = document.createElement("a")
      anchor.href = url
      anchor.download = `${moment(events.find(event => event.id === id).start_time).format("HHmm_DD-MM-YYYY")}.pdf`
      anchor.click()
    })
  }
  

  return(
    <>
      <Container className="my-5">
        <Row>
          <Table>
            <THead>
              <tr>
                <th><Typography bold>ID</Typography></th>
                <th><Typography bold>Ostotapahtuma</Typography></th>
                <th><Typography bold>Varaus (aika)</Typography></th>
                <th><Typography bold>Maksuntila</Typography></th>
                <th><Typography bold>Summa</Typography></th>
                <th><Typography bold>Toiminnot</Typography></th>
                {/* <th><Typography bold>payment completed</Typography></th> */}
              </tr>
            </THead>
            <tbody>
          {events.filter(ev => ev.reserved === true)
            .sort((a, b) => {
              return b.updated.localeCompare(a.updated)
            })
            .map((event, id) => {
              return (
                  <tr key={id}>
                    <td>{event.id}</td>
                    <td><Typography variant={"display5"}>{moment(event.updated).format("DD.MM.YYYY")} / {moment(event.updated).format("HH:mm")}</Typography></td>
                    <td><Typography variant={"display5"}>{moment(event.start_time).format("DD.MM.YYYY HH:mm")} - {timeConvert(event.start_time, event.end_time) === "45min" ? moment(event.end_time).subtract(15, "minutes").format("HH:mm") : moment(event.end_time).subtract(30, "minutes").format("HH:mm")}  ({timeConvert(event.start_time, event.end_time)})</Typography></td>
                    <td>{event.payment_completed !== undefined && event.payment_completed !== null ? <Typography bold className="text-green" variant={"display5"}>Maksettu</Typography> : <Typography bold className="text-red" variant={"display5"}>Kesken</Typography>}</td>
                    <td><Typography bold variant={"display5"}>{timeConvert(event.start_time, event.end_time) === "45min" ? myUser?.min_45_price + '€' : myUser?.min_90_price + '€'} sis alv</Typography></td>
                    <td><Button onClick={() => toggleModal(event.id)}><Typography variant={"display5"}>Kuitit</Typography></Button></td>
                    {/* <td><Button onClick={() => customerReceiptDownloader(event.id)}><Typography variant={"display5"}>Asiakkaan kuitti</Typography></Button></td> */}
                    {/* <td><Typography bold variant={"display5"}>{event.payment_completed}</Typography></td> */}
                  </tr>
              )
            })
          }
          </tbody>
          </Table>
        </Row>
      </Container>


      <Modal
        isOpen={modal}
        toggle={toggleModal}
        centered
      >
        <div className="modal-header p-0 mb-3">
          <Typography className="text-black" variant="h3"bold>Stripe maksupalvelu</Typography>
        </div>
        <div className="modal-body p-0">
          <Row>
            <Col>
              <Row className="mt-2">
                <Col>
                  <Button onClick={() => customerReceiptDownloader(modalData)}>
                    <Typography variant={"display5"}>Asiakkaan kuitti</Typography>
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => therapistReceiptDownloader(modalData)}>
                    <Typography variant={"display5"}>Oma kuitti</Typography>
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  
                </Col>
              </Row>

            </Col>
          </Row>
        </div>
        <div className="modal-footer p-0 mt-5 d-flex justify-content-between">
          <Button
            color="default"
            onClick={toggleModal}
            style={{ width: "180px" }}
          >
            <Typography bold>Valmis</Typography>
          </Button>
        </div>
      </Modal>
    </>
  )
}



const defaultValues = {
  id: null,
  name: '',
}

 const MyAccountEventsFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },



    displayName: "BasicForm"
    
  })(MyAccountEvents)


const MyAccountEventsView = ({match, history}) => {
  // API requests here
  const { t } = useTranslation();
  const { myUser, getMyUser } = useAuthContext()
  const { isAuthenticated } = useAuthContext()
  const [loading, setLoading] = useState(false);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [startTime, setStartTime] = useState(new Date())
  const [endTime, setEndTime] = useState(new Date())
  const [reserved, setReserved] = useState()
  const [events, setEvents] = useState([])
  const [reload, setReload] = useState(false)
  const [title, setTitle] = useState()


  useEffect(() => {
    if (isAuthenticated === true) {
      setLoading(true)
      httpGetEvents({user:myUser.id}).then(res => {
        setEvents(res?.data)
  
        setLoading(false)
        setReload(false)
      })
    } else {
      window.location.replace("/auth/login")
    }
  },[])
  
  const handleChangePassword = () => {
    history.push("/dashboard/myaccount/password");
  }

  const clickMyAccountViewHandler = () => {
    history.push(`/dashboard/myaccount/`)
  }

  const clickCalendarViewHandler = () => {
    history.push(`/dashboard/myaccount/calendar/`)
  }
  
  const clickPaymentViewHandler = () => {
    history.push(`/dashboard/myaccount/payment/`)
  }

  const clickEventsViewHandler = () => {
    history.push(`/dashboard/myaccount/events/`)
  }

  if (loading) {
    return (
      <Container fluid className="saoy_min_height">
        <LoaderScreen />
      </Container>
    )
  }

  return (
    <Container className="mt-4">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard/frontpage"><b>Etusivu</b></a></li>
          <li className="breadcrumb-item active" aria-current="page">{t('Omat tiedot')} / {t('Tapahtumat')}</li>
        </ol>
      </nav>
      <div className="d-flex justify-content-between">
        <div>
          <Row>
            <Col>
              <Button className="header_btn_without_border" variant={'secondary'} onClick={clickMyAccountViewHandler} >
                {myUser?.business_id
                  ? <Typography variant={"h3"}>Perustiedot</Typography>
                  : <Typography className="d-inline-flex" inline variant={"h3"}>Perustiedot&nbsp;<img src={warning} alt="important" /></Typography>
                }
              </Button>
            </Col>
            <Col>
              <Button className="header_btn_without_border" variant={'secondary'} onClick={clickCalendarViewHandler} >
                {events.length > 0 
                  ? <Typography variant={"h3"}>Kalenteri</Typography>
                  : <Typography className="d-inline-flex" inline variant={"h3"}>Kalenteri&nbsp;<img src={warning} alt="important" /></Typography>
                }
              </Button>
            </Col>
            {myUser?.therapist &&
              <Col>
                <Button className="header_btn_without_border" variant={'secondary'} onClick={clickPaymentViewHandler} >
                  {myUser?.stripe_account_completed 
                    ? <Typography inline variant={"h3"}>Maksuasetukset</Typography>
                    : <Typography className="d-inline-flex" inline variant={"h3"}>Maksuasetukset&nbsp;<img src={warning} alt="important" /></Typography>
                  }
                </Button>
              </Col>
            }
            <Col>
              <Button className="header_btn_without_border" variant={'secondary'} onClick={clickEventsViewHandler} >
                <Typography className="active_color" variant={"h3"}>Tapahtumat</Typography>
              </Button>
            </Col>
          </Row>

        </div>
        <div>
          <Button onClick={handleChangePassword}>{t('Vaihda salasana')}</Button>
        </div>
      </div>
      <MyAccountEventsFormik 
        history={history} 
        t={t}
        myUser={myUser} 
        notify={notify} 
        startTime={startTime}
        endTime={endTime}
        events={events}
        setEvents={setEvents}
        setReload={setReload}
        reserved={reserved}
        title={title}
      />
    </Container>
  )
}
  
export default MyAccountEventsView