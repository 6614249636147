import React from "react";
import { Container, Row, Col } from "stories/layout";
import { Typography } from "stories/components";

const PageHeader = (props) => {
  const { title, subtitle, children } = props;
  
  return (
    <Row className="mb-3">
      <div className="col">
        
        <Row>
          <Col>
            <Typography bold variant="h2" className="mb-0">{title}</Typography>
            { subtitle && (
              <Typography className="mb-0">{subtitle}</Typography>
            )}
          </Col>
        </Row>
        
      </div>
    </Row>
  )
}

PageHeader.defaultProps = {
  title: "Please provide title prop"
}

export default PageHeader;