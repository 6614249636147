import React from "react";
import { useTranslation } from "react-i18next";

import { Container, Row, Col } from "reactstrap";
import { Link, Typography } from "stories/components";

import Logo from "assets/images/logo.png";

import "./footer.css";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="footer_container_full">
        <Container className="footer_container">
          <footer className="footer pt-0 footer_container">
            <Row className="align-items-center justify-content-lg-between">
              <Col lg="4" className="mb-4">
                <img src={Logo} className="footer_logo" alt="logo" />
              </Col>

              <Col className="align-self-start">
                <Typography className="mb-2 text-black" bold variant="h1">
                  Psykoterapia
                </Typography>
                <Link
                  className="footer_link"
                  target="_self"
                  isExternal
                  href="https://www.terapeuttini.fi/psykoterapia/mita-on-psykoterapia/"
                >
                  <Typography className="mb-2" bold>
                    {t("Mitä on psykoterapia?")}
                  </Typography>
                </Link>
                <Link
                  className="footer_link"
                  target="_self"
                  isExternal
                  href="https://www.terapeuttini.fi/psykoterapia/terapiamuodot/"
                >
                  <Typography className="mb-2" bold>
                    {t("Terapiamuodot")}
                  </Typography>
                </Link>
                <Link
                  className="footer_link"
                  target="_self"
                  isExternal
                  href="https://www.terapeuttini.fi/psykoterapia/ukk/"
                >
                  <Typography className="mb-2" bold>
                    {t("Ukk")}
                  </Typography>
                </Link>
              </Col>

              <Col className="align-self-start">
                <Typography className="mb-2 text-black" bold variant="h1">
                  Lyhytterapia
                </Typography>
                <Link
                  className="footer_link"
                  target="_self"
                  isExternal
                  href="https://www.terapeuttini.fi/mita-on-lyhytterapia/"
                >
                  <Typography className="mb-2" bold>
                    {t("Mitä on lyhytterapia?")}
                  </Typography>
                </Link>
                <Link
                  className="footer_link"
                  target="_self"
                  isExternal
                  href="https://www.terapeuttini.fi/mita-on-lyhytterapia/ukk-lyhytterapia/"
                >
                  <Typography className="mb-2" bold>
                    {t("Ukk")}
                  </Typography>
                </Link>
              </Col>

              <Col className="align-self-start">
                <Typography className="mb-2 text-black" bold variant="h1">
                  Muut linkit
                </Typography>
                <Link
                  className="footer_link"
                  target="_self"
                  isExternal
                  href="https://www.terapeuttini.fi/ota-yhteytta/"
                >
                  <Typography className="mb-2" bold>
                    {t("Ota yhteyttä")}
                  </Typography>
                </Link>
                <Link
                  className="footer_link"
                  target="_self"
                  isExternal
                  href="https://www.prowork.fi/verkkokauppa/"
                >
                  <Typography className="mb-2" bold>
                    {t("Terapiavalmennus")}
                  </Typography>
                </Link>
                <Link className="footer_link" to={"/auth/login"}>
                  <Typography className="mb-2" bold>
                    {t("Kirjaudu")}
                  </Typography>
                </Link>
              </Col>
            </Row>
          </footer>
        </Container>
      </div>
    </>
  );
};

export default Footer;
