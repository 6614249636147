import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

import {
  FormGroup,
  Input as BootstrapInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import { Typography } from "stories/components"

import './Input.css';

export const Input = ({ type, className, label, labelInline, focused, errorText, required, children, ...rest }) => {
  
  if (type === 'email') {
    return (
      <FormGroup
        className={classnames("mb-3", {
          focused: focused,
        })}
      >
        <div className={`d-flex ${labelInline ? "flex-row align-items-center" : "flex-column"}`}>
          {label && (
              <Typography bold className={`pr-1`}>{label} {required ? "*" : ""}</Typography>
          )}
          <InputGroup className="input-group-merge input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-email-83" />
              </InputGroupText>
            </InputGroupAddon>
            <BootstrapInput
              placeholder="Sähköposti"
              type="email"
              {...rest}
            />
          </InputGroup>
        </div>
        { errorText && (
        <Typography className="text-danger">{errorText}</Typography>
        )}
      </FormGroup>
    )
  }
  else if (type === 'password') {
    return (
      <FormGroup className={classnames({
        focused: focused,
      })}>
        <div className={`d-flex ${labelInline ? "flex-row align-items-center" : "flex-column"}`}>
          {label && (
            <Typography bold className={`pr-1`}>{label} {required ? "*" : ""}</Typography>
          )}
          <InputGroup className="input-group-merge input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-lock-circle-open" />
              </InputGroupText>
            </InputGroupAddon>
            <BootstrapInput
              placeholder="Salasana"
              type="password"
              {...rest}
            />
          </InputGroup>
        </div>
        { errorText && (
        <Typography className="text-danger">{errorText}</Typography>
        )}
      </FormGroup>
    )
  }
  else if (type === 'select') {
    return (
      <FormGroup className={`${classnames({
        focused: focused,
      })} mb-0`}>
        <div className={`d-flex ${labelInline ? "flex-row align-items-center" : "flex-column"}`}>
          {label && (
            <Typography bold className={`pr-1`}>{label} {required ? "*" : ""}</Typography>
          )}
          <BootstrapInput
            label=""
            type="select"
            {...rest}
          >
          {children}
          </BootstrapInput>
        </div>
        { errorText && (
        <Typography className="text-danger">{errorText}</Typography>
        )}
      </FormGroup>
    )
  }
  else {
    return (
      <div className={`d-flex ${labelInline ? "flex-row align-items-center" : "flex-column"}`}>
        {label && (
          <Typography bold className={`pr-1`}>{label} {required ? "*" : ""}</Typography>
        )}
        <BootstrapInput
          className={`custom_bootstrap_input ${className}`}
          type={type ? type : "text"}
          {...rest}
        />
        { errorText && (
          <Typography className="text-danger">{errorText}</Typography>
        )}
      </div>
    )
  }
  
  
};

Input.propTypes = {
  errorText: PropTypes.string,
  focused: PropTypes.bool,
  label: PropTypes.string,
  labelInline: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text','email','password','number','date','datetime-local','select','textarea']),
};

Input.defaultProps = {
  focused: false,
  labelInline: false,
  required: false,
  type: 'text',
};
