import Axios from 'axios'
import appConfig from "./config"
import history from "services/history"

const baseUrl = appConfig.apiUrl;

let axios = Axios.create({
  withCredentials: false,
  baseURL: baseUrl,
})

//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = `jwt ${accessToken}`;
    }
    return config;
  },
  (error) => {
    console.log("api.js:axios.interceptors.request.use:error", error);
    return Promise.reject(error);
  }
);
//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // console.log("axios.interceptors.response.error", error);
    // console.log("axios.interceptors.response.error", error);
    const originalRequest = error?.config;
    
    if (originalRequest?.url === `${baseUrl}/token/refresh/` && error?.response?.status === 401) {
      // console.log("originalRequesturlrefresh401", error);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      // history.push("/auth/login")
      
    }
    else {
      let refreshToken = localStorage.getItem("refreshToken");
      if (
        refreshToken &&
        error?.response?.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        
        const requestOptions = {
          url: `${baseUrl}/token/refresh/`,
          method: "POST",
          headers: {
            
          },
          data: { refresh: refreshToken }
        };
        return axios(requestOptions).then(res => {
          if (res.status === 200) {
            localStorage.setItem("accessToken", res.data.access);
            console.log("Access token refreshed!");
            return axios(originalRequest);
          }
          else if (res.status === 401) {
            console.log("REFRESH 401");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            // history.push("/auth/login")
          }
        }, errorRefresh => {
          console.log("errorRefresh", errorRefresh)
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          // history.push("/auth/login")
        });
      }
      else {
        // localStorage.removeItem("accessToken");
        // localStorage.removeItem("refreshToken");
        // history.push("/auth/login")
      }
      /*
      return axios
        .post(`${baseUrl}/token/refresh/`, { refresh: refreshToken })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("accessToken", res.data.accessToken);
            console.log("Access token refreshed!");
            return axios(originalRequest);
          }
        });
      */
    }
    // console.log("Promise.reject(error)", error)
    // console.log("Promise.reject(error.response)", error?.response)
    // return Promise.reject(error);
    return error?.response
  }
);

/*
//functions to make api calls
const api = {
  signup: (body) => {
    return axios.post(`${baseUrl}/auth/signup`, body);
  },
  login: (body) => {
    return axios.post(`${baseUrl}/auth/login`, body);
  },
  refreshToken: (body) => {
    return axios.post(`${baseUrl}/auth/refresh_token`, body);
  },
  logout: (body) => {
    return axios.delete(`${baseUrl}/auth/logout`, body);
  },
  getProtected: () => {
    return axios.get(`${baseUrl}/protected_resource`);
  },
};
*/
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export default axios;