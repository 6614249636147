console.log("ENV.REACT_APP_ENV", process?.env?.REACT_APP_ENV);

let apiUrl = 'http://localhost:8000/api';

if (process?.env?.REACT_APP_ENV === "prod") {
  apiUrl = 'https://app.terapeuttini.fi/api';
}
else if (process?.env?.REACT_APP_ENV === "lenovo") {
  apiUrl = 'http://localhost:8000/api';
}
else if (process?.env?.REACT_APP_ENV === "moltres") {
  apiUrl = 'http://localhost:8000/api';
}
else if (process?.env?.REACT_APP_ENV === "dev") {
  apiUrl = 'http://172.211.166.252/api';
}

const config = {
  apiUrl: apiUrl,
  TEST_NO_BACKEND: false,
}

console.log("config", config);

export default config;