import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal as BootstrapModal,
} from "reactstrap";
import './Modal.css';

import { IconButton } from "stories/components";

export const Modal = ({ title="", isOpen=false, toggleModal, closeModal, buttons, className, size, children, ...rest }) => {
  
  const handleClose = () => {
    if (closeModal) {
      closeModal()
    }
    else if (toggleModal) {
      toggleModal()
    }
  }
  
  return (
    <BootstrapModal
      className={`modal-dialog-centered ${className ? className : ""} ${size ? size : ""}`}
      isOpen={isOpen}
      toggle={toggleModal}
      {...rest}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
        {title}
        </h5>
        {/* <IconButton aria-label="Close" size="lg" onClick={handleClose} iconName="times" data-dismiss="modal" /> */}
      </div>
      <div className="modal-body pt-0">
      {children}
      </div>
      
      { buttons && (
        <div className="modal-footer">
          <div style={{width:"100%"}} className="d-flex justify-content-between">
            {buttons}
          </div>
        </div>
      )}
    </BootstrapModal>
  )
};

Modal.propTypes = {
  buttons: PropTypes.array,
  className: PropTypes.string,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  size: PropTypes.oneOf(['modal-sm','modal-lg','modal-xl']),
  title: PropTypes.string,
  toggleModal: PropTypes.func,
};

