export const cleanEmail = (email) => {
  return email.toLowerCase().replace(" ", "");
}

export const listToString = (list) => {
  if (list == null || list.length == 0) {
    return "";
  }
  const size = list.length;
  return list && list.map((elem, i) => {
    let txt = elem.name;
    if (i < size-1) {
      txt += ","
    }
    return txt;
  })
}

export const round = (value, precision) => {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}