import { createStore, action, thunk, reducer, persist } from 'easy-peasy';
import authModel from './auth-model';
import exampleModel from './example-model';
import profileModel from './profile-model'

export const store = createStore({
  auth: authModel,
  example: exampleModel,
  profile: profileModel,
});
