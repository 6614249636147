import { action, thunk, persist } from 'easy-peasy';
import { httpGetExamples } from "services/example";

const model = {
  objects: [],
  setObjects: action((state, payload) => {
    state.objects = [
      ...payload,
    ]
  }),
  addObject: action((state, payload) => {
    state.objects = [
      ...state.objects,
      payload,
    ]
  }),
  getObjects: thunk(async (actions, payload) => {
    
    const params = {
      ordering: 'name',
      ...payload,
    }
    httpGetExamples(params).then(response => {
      actions.setObjects(response.data)
    }, error => {
      
    })
    
  }),

  count: 0,
  setCount: action((state, payload) => {
    state.count = payload
  }),
};

export default model;