import { Oval } from "react-loader-spinner";
// import { Container } from "stories/layout";

const LoaderScreen = () => {
  return (
    // <Container>
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Oval
        ariaLabel="loading-indicator"
        height={200}
        width={200}
        strokeWidth={5}
        strokeWidthSecondary={5}
        color="#1F63E1"
        secondaryColor="#F4F7FF"
      />
    </div>
    // </Container>
  );
};

export default LoaderScreen;
