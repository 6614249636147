import React from 'react';
import PropTypes from 'prop-types';
import { Button as BootstrapButton } from "reactstrap";
import { Badge } from "./Badge"
import './button.css';

export const Button = ({ variant, badgeColor, badgeValue, children, className, loading=false, disabled=false, ...rest }) => {
  
  let classNameCommon = `${className ? className : ""} ${variant && variant === "link" ? "px-0 py-1" : ""}`;
  
  return (
    <BootstrapButton
      className={`${classNameCommon}`}
      type="button"
      color={variant}
      disabled={disabled || loading}
      {...rest}
    >
      { children }
      { loading && (
        <div className="button_loader d-flex justify-content-center align-items-center"><div><span className="spinner-border spinner-border-sm"></span></div></div>
      )}
      { badgeValue && (<Badge style={{marginLeft: 0, top: "-24px", right: "0px"}} color={badgeColor} type="floating">{badgeValue}</Badge>)}
    </BootstrapButton>
  );
};

Button.propTypes = {
  badgeColor: PropTypes.oneOf(['primary','secondary','default','info','success','danger','warning']),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'normal', 'lg']),
  variant: PropTypes.oneOf(['primary','secondary','default','info','success','danger','warning','link']),
};

Button.defaultProps = {
  badgeColor: "default",
  badgeValue: null,
  onClick: undefined,
  size: 'normal',
  variant: 'primary',
};