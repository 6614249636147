import { useTranslation } from 'react-i18next';
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Navbar } from "reactstrap";

import { Button, Link } from "stories/components"
import { useAuthContext } from "contexts/AuthContext"
import { Col, Row } from "stories/layout"

import Logo from "assets/images/logo.png"

function AdminNavbar({ history, theme, topNavOpen }) {
  const { isAuthenticated, logout } = useAuthContext();
  const { t } = useTranslation();

  const handleFirstpage = () => {
    history.push("/dashboard/frontpage")
  }

  const handleMyAccount = () => {
    history.push("/dashboard/myaccount")
  }

  const handleLogout = () => {
    logout()
  }

  // const handleLogin = () => {
  //   history.push("/auth/login")
  // }

  // const handleMyAccountPas = () => {
  //   history.push("/myaccount/password")
  // }
  
  // const handleRegister = () => {
  //   history.push("/auth/register")
  // }
  
  
  return (
    <>
      <div className="w-100 bg-primary">
      <Navbar
        className={classnames(
          "w-100 navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-primary": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" },
          { "d-none": topNavOpen === false },
        )}
      >
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div className="mx-2 " style={{ paddingLeft: "2.5vw"}}>
            <Link to={`/dashboard/frontpage`}><img src={Logo} style={{ width: "100%", maxWidth: "314px" }} alt="logo" /></Link>
          </div>
          
          <div className="mx-2">
            &nbsp;
          </div>
          
          { isAuthenticated ? (
            <Row className="d-flex justify-content-end mr-3">
              <Col className="px-1 py-1">
                <Button color="darker text-uppercase" onClick={handleFirstpage}>ETUSIVU</Button>
              </Col>
              <Col className="px-1 py-1">
                <Button color="darker text-uppercase" onClick={handleMyAccount} style={{ width: "110px" }}>OMA TILI</Button>
              </Col>
              <Col className="px-1 py-1">
                <Button color="darker text-uppercase text-nowrap" onClick={handleLogout}>{t("Kirjaudu ulos")}</Button>
              </Col>
            </Row>
          ) : (
          <>
          </>
          )}
          
        </div>

      </Navbar>
      </div>
    </>
  );
}

AdminNavbar.defaultProps = {
  // toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  // toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
