import React, { useEffect, useState } from 'react'
import { httpGetMyUser, httpUpdateUser } from "services/users"

import './ProfileImgUploader.css'
import defaultImg from 'assets/images/profile.jpg'

const ProfileImgUploader = ({ profileImage, setProfileImage }) => {
  // const [profileImg, setProfileImg] = useState()
  const [imagePreview, setImagePreview] = useState(defaultImg)
  const [state, setState] = useState()

  useEffect(() => {
    httpGetMyUser().then(res => {
      if (res?.data.profile_image != null) {
        setProfileImage(res?.data.profile_image)
        setImagePreview(res?.data.profile_image)
        setState("profiilikuva")
      } else {
        setState("oletus")
      }
    }) 
  },[])

  const imageHandler = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImage(file)
        setImagePreview(reader.result)
        setState("lisätty")
      }
    }
    reader.readAsDataURL(file)
  }

  const submitHandler = () => {
    const formData = new FormData()
    formData.append('profile_image', profileImage)
    httpUpdateUser("me", formData)
    setState("profiilikuva")
  }

  return(
    <div className='page_imgUploader'>
      <div className='container_imgUploader'>
        <h1 className='heading_imgUploader'>Profiili kuva</h1>
        <div className='img-holder_imgUploader'>
          <img src={imagePreview} alt='' id='img' className='img_imgUploader' />
        </div>

        {state === "oletus" &&
          <>
            <input type="file" name="image-upload" id='input' accept='image/*' onChange={imageHandler}/>
            <div className='label_imgUploader'>
              <label htmlFor='input' className='image-upload_imgUploader'>
                  Valitse kuva
              </label>
            </div>
          </>
        }

        {state === "lisätty" &&
          <>
            <div className='label_imgUploader'>
              <button type="submit" className='image-save_imgUploader' onClick={submitHandler}>
                Tallenna
              </button>
            </div>
          </>
        }

        {state === "profiilikuva" &&
          <>
            <input type="file" name="image-upload" id='input' accept='image/*' onChange={imageHandler}/>
            <div className='label_imgUploader'>
              <label htmlFor='input' className='image-upload_imgUploader'>
                  Vaihda kuva
              </label>
            </div>
          </>
        }

      </div>
    </div>
  )
  
}

export default ProfileImgUploader