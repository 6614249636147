import React, { useEffect, useState} from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footers/Footer.js";

import { useAuthContext } from "contexts/AuthContext"
import routesAdmin from "routes_dashboard.js";
import { IconButton } from "stories/components";

const showTopNavigationToggleButton = false;

function Dashboard({history, sidenavAlwaysOpen}) {
  const { myUser } = useAuthContext()
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const [router, setRouter] = useState();
  
  useEffect(() => {
    if (document?.documentElement) {
      document.documentElement.scrollTop = 0;
    }
    if (document?.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
    if (mainContentRef?.current) {
      mainContentRef.current.scrollTop = 0;
    }
  }, [location]);
  
  
  useEffect(() => {
    setRouter(routesAdmin)
  }, [myUser])
  
  
  // Top navigation
  const [topNavOpen, setTopNavOpen] = useState(true);
  const [topNavButtons] = useState([
    {
      name: "Nav1",
      url: "",
      icon_font_awesome: "fas fa-plus-circle",
    },
    {
      name: "Nav2",
      url: "",
      icon_font_awesome: "fas fa-briefcase",
    },
  ]);
  
  const toggleTopNav = () => {
    setTopNavOpen(s => !s)
  }
  
  const getRoutes = (_router) => {
    if (!_router) {
      return;
    }
    
    // Returns only right routes, by user permissions and layout
    return _router.filter((route, key) => {
      if (route?.layout === "/dashboard") {
        if (route?.permissions === 3) {
          if (myUser?.is_staff === true) {
            return true;
          }
        }
        else {
          return true;
        }
      }
      return false;
    })
  }
  
  const listRoutes = (_router) => {
    if (!_router) {
      return;
    }
    // Construct Route components
    const filteredRoutes = getRoutes(_router);
    
    return filteredRoutes.map((prop, key) => {
      if (!prop.component && !prop.collapse) {
        return null;
      }
      if (prop.collapse) {
        return listRoutes(prop.views);
      }
      if (prop.layout === "/dashboard") {
        if (prop?.permissions === 3) {
          if (myUser?.is_staff === true) {
            return (
              <Route
                exact={prop.exact ? prop.exact : undefined}
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          }
        }
        else {
          return (
            <Route
              exact={prop.exact ? prop.exact : undefined}
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    if (!router) {
      return "";
    }
    for (let i = 0; i < router.length; i++) {
      if (location.pathname.indexOf(router[i].layout + router[i].path) !== -1) {
        return router[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (sidenavAlwaysOpen) {
      return;
    }
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  
  if (router) {
    return (
      <>
        <div className="main-content" ref={mainContentRef}>
          <AdminNavbar
            history={history}
            theme={"dark"}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            brandText={getBrandText(location.pathname)}
            myUser={myUser}
            topNavOpen={topNavOpen}
            toggleTopNav={toggleTopNav}
            topNavButtons={topNavButtons}
          />
          { showTopNavigationToggleButton && (
          <div style={{position:"absolute", right:"4px", top: topNavOpen ? "0px" : "-12px"}}>
            <IconButton color="secondary" size="lg" iconName={topNavOpen ? "times" : "angle-down"} onClick={toggleTopNav} />
          </div>
          )}
          <>
            <Switch>
              {listRoutes(router)}
              <Redirect from="*" to="/dashboard/frontpage" />
            </Switch>
          </>
          <Footer />
        </div>

      </>
    );
  }
  return (
    <></>
  );
}

Dashboard.defaultProps = {
  sidenavAlwaysOpen: true,
}

export default Dashboard;
