import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { withFormik } from 'formik';
import moment from "moment"
import fi from 'date-fns/locale/fi'


import { httpGetEvents, httpUpdateEvent, httpAddEvent, httpDeleteEvent } from "services/events";
import { useAuthContext }from "contexts/AuthContext"
import { useNotification } from "stories/components/Notification"

import { Button, Typography, Modal } from "stories/components";
import { Container, Row, Col } from "stories/layout";
import { Input } from "stories/forms";

import trashcan from 'assets/img/icons/common/trash-can-regular.svg'
import free from 'assets/img/icons/common/circle-fill.svg'
import reserved from 'assets/img/icons/common/circle-reserved.svg'

import Select from "react-select";
import Calendar from "react-calendar";

import DatePicker, { registerLocale } from "react-datepicker";
import Switch from 'react-switch'

import 'stories/components/calendar.css'
import "react-datepicker/dist/react-datepicker.css"
import LoaderScreen from "components/LoaderScreen"

import warning from 'assets/img/icons/common/circle-warning.svg'

import appConfig from "services/config"

const baseUrl = appConfig.apiUrl;

registerLocale('fi', fi)
moment.locale('fi')

const datesAreOnSameDay = (first, second) => {
  if (
    moment(first).format('l') === moment(second).format('l')
  )
  return true
}

const MyAccountCalendar = (props) => {
  const {
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    events,
    setEvents,
    repeatFrequency,
    setRepeatFrequency,
    repeatTimes,
    setRepeatTimes,
    setReload,
  } = props

  const {
    t,
    errors,
    handleSubmit,
  } = props;
  
  const [modal, setModal] = useState(false)
  const [nestedModal, setNestedModal] = useState(false)
  const [closeAll, setCloseAll] = useState(false)
  const [howLong, setHowLong] = useState({})
  const [modalData, setModalData] = useState()

  const isEmpty = Object.keys(howLong).length === 0

  useEffect(() => {
    setRepeatFrequency( { value: 0, label: 'Kerran' })
    setRepeatTimes({ value: 1, label: '1' })
  },[])

  // useEffect(() => {
  //   console.log("events",events)
  // },[events])

  useEffect(() => {
    endTimeHandler()
  },[startTime, howLong])

  const timeList = events.map((d) => (d.start_time))
  const reservedList = events.filter(event => event.reserved === true).map((d) => d.start_time)

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      if (reservedList.find(dDate => datesAreOnSameDay(dDate, date))) {
        return <img className="calender_reservedTab" src={reserved} alt="reserved" />
      }
      if (timeList.find(dDate => datesAreOnSameDay(dDate, date))) {
        return <img className="calender_freeTab" src={free} alt="free time"  />
      }
    }
  }

  const dateHandler = (nextValue) => {
    setStartTime(nextValue)
  }
  
  const dateCleaner = (date) => {
    return `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`
  }

  const repeatFrequencyList = [
    { value: 0, label: 'Kerran' },
    { value: 2, label: 'Viikottain' },
    { value: 3, label: 'Kuukausittain'}
  
  ]
  const repeatTimesList = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
  ]

  const howLongList = [
    { value: 45, label: '45 min' },
    { value: 90, label: '90 min' }
  ]

  const repeatFrequencyHandler = (event) => {
    setRepeatFrequency(event)
  }
  
  const repeatTimesHandler = (event) => {
    setRepeatTimes(event)
  }

  const howLongHandler = (event) => {
    setHowLong(event)
  }

  const endTimeHandler = () => {
    if (howLong?.value === 45) {
      setEndTime(moment(startTime).add(60, 'minutes'))
    }else{
      setEndTime(moment(startTime).add(120, 'minutes'))
    }
  }

  const submitHandler = () => {
    setReload(true)
    handleSubmit()
  }

  const titleHandler = (event) => {
    setModalData(s => ({
      ...s,
      title: event.target.value
    }))
  }

  const descriptionHandler = (event) => {
    setModalData(s => ({
      ...s,
      description:event.target.value
    }))
  }

  const reservedHandler = () => {
    setModalData(s => ({
      ...s,
      reserved: (!s.reserved)
    }))
  }

  const timeHandler = (event) => {
    setModalData(s => ({
      ...s,
      start_time: event,
    }))
  }

  const openFromParent = (data) => {
    setModalData(data)
    setModal(true)
  }

  const toggleModal = () => {setModal(!modal)}

  const toggleNested = () => {
    setNestedModal(!nestedModal)
    setCloseAll(false)
  }

  const toggleAll = () => {
    setNestedModal(!nestedModal)
    setCloseAll(true)
  }

  const trashHandler = () => {
    httpDeleteEvent(modalData.id)
    toggleAll()
    setReload(true)
  }

  const updateHandler = () => {
    const values = modalData
    const {start_time} = modalData

    let end_time

    if (howLong?.value === 45) {
      end_time = moment(start_time).add(60, 'minutes')
    }else{
      end_time = moment(start_time).add(120, 'minutes')
    }

    let data = {
      ...values, start_time: start_time, end_time: end_time
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': `jwt ${localStorage.getItem('accessToken')}` 
      }
    }

    if (values?.reserved === false && events?.find(event => event.id === values?.id).reserved === true) {
      fetch(`${baseUrl}/events/${values?.id}/cancel_event_reservation_therapist/`, requestOptions)
        .then(res => {
          if (res.status === 200) {
            // console.log("status:",res.status)
            props.notify({ title:t("Kalenteri"), message:t("Päivitetty onnistuneesti")})
            setReload(true)
          } else {
            props.notify({ title:t("Kalenteri"), message:t("päivityksessä virhe")})
          }
        })
      
    } else {
      httpUpdateEvent(values.id, data).then(res => {
        props.notify({ title:t("Kalenteri"), message:t("Päivitetty onnistuneesti")})
      }, error => {
      }) 
      setReload(true)
    }
  }    

  return(
    <>
      <Container fluid className="my-5">
        <Row >
          <Col md="1"></Col>
          <Col>

            {/* LISÄÄ VAPAAAIKA NÄKYMÄ */}
            <Row>
              <Col>
                <Row>
                  <Typography variant={'h1'}>Lisää vapaa vastaanottoaika</Typography>
                </Row>
                <Row className="mt-1">
                  <Col className="pl-0">
                    <Container className="bg-info border p-3">
                      {/* <Row>
                        <Col>
                          <Typography variant={'display5'}>Valitse päivä ja aika</Typography>
                        </Col>
                      </Row>
                      <Row sm="12" className="mt-3">
                        <Col sm="6">
                          <DatePicker className="w-100 datepicker"
                            locale="fi" 
                            selected={startTime} 
                            onChange={(date) => setStartTime(date)} 
                            // minDate={subDays(new Date(), 5)}
                            // showTimeSelect
                            showTimeInput
                            timeInputLabel="Aika"
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="dd.MM.yyyy HH:mm"
                            showWeekNumbers
                          />
                        </Col>
                      </Row> */}

                      <Row sm="12" className="mt-4">
                        <Col xs="12" sm="6">
                          <Row>
                            <Col>
                              <Typography variant={'display5'}>Valitse päivä ja aika</Typography>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col>
                              <DatePicker className="w-100 datepicker"
                                locale="fi" 
                                selected={startTime} 
                                onChange={(date) => setStartTime(date)} 
                                // minDate={subDays(new Date(), 5)}
                                // showTimeSelect
                                showTimeInput
                                timeInputLabel="Aika"
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="dd.MM.yyyy HH:mm"
                                showWeekNumbers
                              />
                            </Col>
                          </Row>


                          {/* <Row>
                            <Col>
                              <Typography variant={'display5'}>Valitse alkamisaika</Typography>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col>
                              <DatePicker className="w-100 datepicker"
                                selected={startTime}
                                onChange={(time) => setStartTime(time)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Aika"
                                dateFormat='HH:mm'
                                timeFormat='HH:mm'
                              />
                            </Col>
                          </Row> */}
                        </Col>
                        <Col xs="12" sm="6">
                          <Row>
                            <Col>
                              <Typography variant={'display5'}>Valitse kesto</Typography>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col>
                              <Select 
                                placeholder={"Valitse kesto"}
                                options={howLongList}
                                selected={howLong}
                                onChange={howLongHandler}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row sm="12" className="mt-2">
                        <Col xs="12" sm="6">
                          <Row>
                            <Col>
                              <Typography variant={'display5'}>Toisto</Typography>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col>
                              <Select 
                                // placeholder={"Valitse toisto"}
                                options={repeatFrequencyList}
                                defaultValue={{ label: repeatFrequencyList[0].label, value: repeatFrequencyList[0].value }}
                                selected={repeatFrequency}
                                onChange={repeatFrequencyHandler}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs="12" sm="6">
                          <Row>
                            <Col>
                              <Typography variant={'display5'}>Monta kertaa</Typography>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col>
                              <Select 
                                // placeholder={"Valitse toisto"}
                                options={repeatTimesList}
                                defaultValue={{ label: repeatTimesList[0].label , value: repeatTimesList[0].value }}
                                selected={repeatTimes}
                                onChange={repeatTimesHandler}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                                              
                    <Button className="mt-4 btn btn-block" variant="success" disabled={isEmpty} onClick={submitHandler}>LISÄÄ KALENTERIIN</Button>
                    { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }

                    </Container>
                  </Col>
                </Row>
              </Col>
            </Row>


            {/* KALENTERI NÄKYMÄ */}
            <Row className="mt-5">
              <Col>
                <Typography variant={'h1'}>Hallitse kalenteria</Typography>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>
                <Calendar 
                    onChange={dateHandler} 
                    value={startTime}
                    tileContent={tileContent}
                    next2Label={null} 
                    prev2Label={null} 
                  />
              </Col>
            </Row>

            {/* MUOKKAA TAPAHTUMIA NÄKYMÄ */}
            <Row className="mt-5">
              <Col>
                <Row>
                  <Typography variant={'h1'}>Muokkaa päivän tapahtumia {dateCleaner(startTime)}</Typography>
                </Row>
                <Row className="mt-1">
                  <Col className="pl-0">
                    <Container className="bg-info border p-3">
                      {events.filter(d => moment(startTime).format('l') === moment(d.start_time).format('l'))
                        .sort((a, b) => moment(a.start_time).format('LT') - moment(b.start_time).format('LT'))
                        .map((e) => (
                          <Container key={e.id} className="bg-primary border p-2 mb-2">
                            <Row className="d-flex justify-content-between">
                              <div className="pl-3">
                                <Row>
                                  <Col>
                                    <Typography inline variant={'h4'}>{e.title}</Typography>
                                  </Col>
                                  {e.reserved &&
                                    <Col>
                                      <Typography inline variant={'h5'}>{e.use_remote_appointment ? "Etävastaanotto" : "Lähitapaaminen"}</Typography>
                                    </Col>
                                  }
                                </Row>
                                <Row className="mt-2">
                                  <Col>
                                    <Typography variant={'h5'}>{e.use_kela ? "Kela korvaus" : ""}</Typography>
                                  </Col>
                                </Row>
                                <Row className="mt-2">
                                  <Col>
                                    <Typography variant={'small'}>{e.description}</Typography>
                                  </Col>
                                </Row>
                                <Row className="mt-2">
                                <Col>
                                  {/* <Typography variant={'display5'}>{e.start_time} - {e.end_time}</Typography> */}
                                  <Typography variant={'display5'}>{moment(e.start_time).format('HH.mm')} - {moment(e.end_time).format('HH.mm')}</Typography>
                                </Col>
                              </Row>
                              </div>
                              <div className="pr-3 my-auto">
                                <Button onClick={() => openFromParent(e)} >
                                  <Typography>Muokkaa</Typography>
                                </Button>
                              </div>
                            </Row>
                          </Container>
                      ))}
                    </Container>
                  </Col>
                </Row>
              </Col>
            </Row>

          </Col>
          <Col md="1"></Col>
        </Row>
      </Container>

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        centered
      >
        <div className="modal-header p-0 mb-3">
          <Typography className="text-black" variant="h3" bold>Muokkaa valittua aikaa</Typography>
          
          {events?.find(event => event.id === modalData?.id)?.reserved === true 
            ? ""
            : 
              <Button onClick={toggleNested}>
                <img src={trashcan} alt="trashcan" height="30px" style={{ fill: '#E62C22' }} />
              </Button>
          }
          
          <Modal isOpen={nestedModal} toggle={toggleNested} onClosed={closeAll ? toggleModal : undefined}>
              <div className="modal-header">
                <Typography bold variant="h3">Kalenterimerkinnän poisto</Typography>
                </div>
              <div className="modal-body">Haluatko varmasti poistaa?</div>
              <div className="modal-footer p-0 mt-5 d-flex justify-content-between">
                <Button 
                  color="default" 
                  onClick={toggleNested}
                  style={{ width: '180px'}}
                >
                  Peruuta
                </Button>
                <Button 
                  color="danger" 
                  onClick={trashHandler}
                  style={{ width: '180px'}}
                >
                  Poista
                </Button>
              </div>
            </Modal>
        </div>
        <div className="modal-body p-0">
          <Row>
            <Col>
              <Row className="mt-2">
                <Col>
                  <Typography variant={'display5'}>Tapahtuma</Typography>
                  <Input
                    className="mt-2"
                    value={modalData?.title}
                    onChange={titleHandler}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Typography variant={'display5'}>Lisätiedot</Typography>
                  <Input
                    className="mt-2"
                    value={modalData?.description}
                    onChange={descriptionHandler}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Typography variant={'display5'}>Muokkaa aikaa</Typography>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <DatePicker className="w-50 datepicker"
                    selected={moment(modalData?.start_time).toDate()}
                    onChange={timeHandler}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Aika"
                    dateFormat='HH:mm'
                    timeFormat='HH:mm'
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Typography variant={'display5'}>Valitse kesto</Typography>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Select className="w-50" 
                    placeholder={"Valitse kesto"}
                    options={howLongList}
                    selected={howLong}
                    onChange={howLongHandler}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Typography inline bold>Muokattu aika:&nbsp;</Typography>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <label>
                    <Switch 
                      onChange={reservedHandler} 
                      checked={(modalData?.reserved && modalData?.reserved) || false}
                      width={100}
                      offColor="#5e72e4"
                      onColor="#45C915"
                      uncheckedIcon={
                        <div style={{ 
                          display: "flex",
                          justifyContent: "right",
                          alignItems: "right",
                          height: "100%",
                          fontSize: 15,
                          color: "white",
                          paddingRight: "10px"
                        }}>
                          Vapaa
                        </div>
                      }
                      checkedIcon={
                        <div style={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "left",
                          height: "100%",
                          fontSize: 15,
                          color: "white",
                          paddingLeft: "10px"
                        }}>
                          Varattu
                        </div>
                      }
                    />
                  </label>
                  {events?.find(event => event.id === modalData?.id)?.reserved === true &&
                    <Typography>Varatun ajan peruuttaminen palauttaa automaattisesti rahat asiakkaalle</Typography>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="modal-footer p-0 mt-5 d-flex justify-content-between">
          <Button
            color="default"
            onClick={toggleModal}
            style={{ width: "180px" }}
          >
            <Typography bold>Peruuta</Typography>
          </Button>
          <Button 
            color="success" 
            onClick={updateHandler}
            style={{ width: "180px" }}
          >
            <Typography bold>Tallenna</Typography>
          </Button>
        </div>
      </Modal>
    </>
  )
}



const defaultValues = {
  id: null,
  name: '',
}

 const MyAccountCalendarFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },

    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      // console.log('handleSubmit')
      const {t, startTime, endTime, myUser, repeatFrequency, repeatTimes } = props;
      
      let title = 'Vapaa aika'

      let description = ''

      let start = moment(startTime).toISOString().split('.')[0]+'Z'

      let end = moment(endTime).toISOString().split('.')[0]+'Z'

      let erotus = moment.utc(moment(endTime,"DD/MM/YYYY HH:mm:ss").diff(moment(startTime,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")

      let userId = myUser.id

      let reserved = false

      let repeatFrequencySend = repeatFrequency.value

      let repeatTime = repeatTimes.value

      let eventsData = []

      const event1 = {
        ...values, start_time: start, end_time: moment(startTime).add(60, 'minutes').toISOString().split('.')[0]+'Z', user: userId, reserved: reserved, title: title, repeats: repeatTime, repeat_frequency: repeatFrequencySend, description: description
      }
      eventsData.push(event1)

      if (erotus > "01:00:00") {
        const event2 = {
          ...values, start_time: moment(startTime).add(60, 'minutes').toISOString().split('.')[0]+'Z', end_time: moment(startTime).add(120, 'minutes').toISOString().split('.')[0]+'Z', user: userId, reserved: reserved, title: title, repeats: repeatTime, repeat_frequency: repeatFrequencySend, description: description
        }
        eventsData.push(event2)
      }

      // console.log("erotus",moment.utc(moment(endTime,"DD/MM/YYYY HH:mm:ss").diff(moment(startTime,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss"))


      eventsData.map(data => {
        httpAddEvent(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Kalenteri"), message:t("Päivitetty onnistuneesti")})
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        }) 
      })     
    },

    displayName: "BasicForm"
    
  })(MyAccountCalendar)


const MyAccountCalendarView = ({match, history}) => {
  // API requests here
  const { t } = useTranslation();
  const { myUser, getMyUser } = useAuthContext()
  const { isAuthenticated } = useAuthContext()
  const [loading, setLoading] = useState(false);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [startTime, setStartTime] = useState(new Date())
  const [endTime, setEndTime] = useState(new Date())
  const [reserved, setReserved] = useState()
  const [repeatFrequency, setRepeatFrequency] = useState()
  const [repeatTimes, setRepeatTimes] = useState()
  const [events, setEvents] = useState([])
  const [reload, setReload] = useState(false)
  const [title, setTitle] = useState()


  useEffect(() => {
    if (isAuthenticated === true) {
    } else {
      window.location.replace("/auth/login")
    }
  },[])

  useEffect(() => {  
    setLoading(true)
    httpGetEvents({user:myUser.id}).then(res => {
      setEvents(res?.data)
      setLoading(false)
      setReload(false)
    })
  },[reload])

  
  const handleChangePassword = () => {
    history.push("/dashboard/myaccount/password");
  }

  const clickMyAccountViewHandler = () => {
    history.push(`/dashboard/myaccount/`)
  }

  const clickCalendarViewHandler = () => {
    history.push(`/dashboard/myaccount/calendar/`)
  }
  
  const clickPaymentViewHandler = () => {
    history.push(`/dashboard/myaccount/payment/`)
  }
  
  const clickEventsViewHandler = () => {
    history.push(`/dashboard/myaccount/events/`)
  }

  if (loading) {
    return (
      <Container fluid className="saoy_min_height">
        <LoaderScreen />
      </Container>
    )
  }

  return (
    <Container className="mt-4">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard/frontpage"><b>Etusivu</b></a></li>
          <li className="breadcrumb-item active" aria-current="page">{t('Omat tiedot')} / {t('Kalenteri')}</li>
        </ol>
      </nav>
      <div className="d-flex justify-content-between">
        <div>
          <Row>
            <Col>
              <Button className="header_btn_without_border" variant={'secondary'} onClick={clickMyAccountViewHandler}>
                {myUser?.business_id
                  ? <Typography className="text-dark" variant={"h3"} >Perustiedot</Typography>
                  : <Typography className="d-inline-flex text-dark" inline variant={"h3"}>Perustiedot&nbsp;<img src={warning} alt="important" /></Typography>
                }
              </Button>
            </Col>
            <Col>
              <Button className="header_btn_without_border" variant={'secondary'} onClick={clickCalendarViewHandler}>
                {events.length > 0 
                  ? <Typography className="active_color" variant={"h3"}>Kalenteri</Typography>
                  : <Typography className="d-inline-flex active_color" inline variant={"h3"}>Kalenteri&nbsp;<img src={warning} alt="important" /></Typography>
                }
              </Button>
            </Col>
            {myUser?.therapist &&
              <Col>
                <Button className="header_btn_without_border" variant={'secondary'} onClick={clickPaymentViewHandler} >
                  {myUser?.stripe_account_completed 
                    ? <Typography className="text-dark" inline variant={"h3"}>Maksuasetukset</Typography>
                    : <Typography className="text-dark d-inline-flex" inline variant={"h3"}>Maksuasetukset&nbsp;<img src={warning} alt="important" /></Typography>
                  }
                </Button>
              </Col>
            }
            <Col>
              <Button className="header_btn_without_border" variant={'secondary'} onClick={clickEventsViewHandler} >
                <Typography className="text-dark" variant={"h3"}>Tapahtumat</Typography>
              </Button>
            </Col>
          </Row>

        </div>
        <div>
          <Button onClick={handleChangePassword}>{t('Vaihda salasana')}</Button>
        </div>
      </div>
      <MyAccountCalendarFormik 
        history={history} 
        t={t}
        myUser={myUser} 
        notify={notify} 
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
        events={events}
        setEvents={setEvents}
        repeatFrequency={repeatFrequency}
        setRepeatFrequency={setRepeatFrequency}
        repeatTimes={repeatTimes}
        setRepeatTimes={setRepeatTimes}
        setReload={setReload}
        reserved={reserved}
        setReserved={setReserved}
        title={title}
        setTitle={setTitle}
      />
    </Container>
  )
}
  
export default MyAccountCalendarView