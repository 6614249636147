import React from "react"
// react library for routing
import { Link } from "react-router-dom"
// reactstrap components
import { Navbar } from "reactstrap"


import { Button } from "stories/components"

import Logo from "assets/images/logo.png"

function AdminNavbar({ history }) {

  // const handleRegister = () => {
  //   history.push("/auth/register")
  // }
  
  // const handleLogin = () => {
  //   history.push("/auth/login")
  // }
  
  // const handleFront = () => {
  //   history.push("/dashboard/frontpage")
  // }

  return (
    <>
      <div className="w-100 bg-primary navbar-light mt-3">
        <Navbar
          className={
            "w-100 navbar-top navbar-expand border-bottom navbar-light pl-5"
          }
        >
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div className="mx-2">
              <Link to={`/dashboard/frontpage`}><img src={Logo} alt="logo" style={{ width: "100%", maxWidth: "314px" }} /></Link>
              {/* <Typography variant="h1" className="mb-2">{('ProWork')}</Typography>
              <Typography variant="h2" color='#0ED9D9' className="mb-2">{('portaali')}</Typography> */}
            </div>
            {/* <div>
              <Link to={`/dashboard/frontpage`}><Button variant={'darker'}>ETUSIVU</Button> </Link>
              <Button variant={'darker'} >OMA TILI</Button>
            </div> */}

            {/* <div className="mx-2">
              &nbsp;
            </div> */}
          </div>
        </Navbar>
      </div>
    </>
  )
}

export default AdminNavbar
