import http from './api'

const url = '/users/'

export const httpGetUsers = (params) => {
  console.log("httpGetUsers");
  return http({
    url: `${url}` ,
    method: 'GET',
    params,
  })
}

export const httpGetUser = (id) => {
  console.log("httpGetUser");
  return http({
    url: `${url}${id}/` ,
    method: 'GET',
  })
}

export const httpGetMyUser = () => {
  console.log("httpGetMyUser");
  return http({
    url: `${url}me/` ,
    method: 'GET',
  })
}

export const httpRegisterUser = (data) => {
  console.log("httpRegisterUser");
  return http({
    url: `${url}` ,
    method: 'POST',
    data,
  })
}



export const httpSendPasswordResetConfirmation = (data) => {
  console.log("httpSendPasswordResetConfirmation");
  return http({
    url: `/send_password_reset_confirmation/` ,
    method: 'POST',
    data,
  })
}

export const httpResetPassword = (data) => {
  console.log("httpResetPassword");
  return http({
    url: `/reset_password/` ,
    method: 'POST',
    data,
  })
}

export const httpCreateUser = (data) => {
  console.log("httpCreateUser");
  return http({
    url: `${url}` ,
    method: 'POST',
    data
  })
}

export const httpUpdateUser = (id, data) => {
  console.log("httpUpdateUser");
  return http({
    url: `${url}${id}/` ,
    method: 'PATCH',
    data
  })
}

export const httpChangePassword = (id, data) => {
  console.log("httpChangePassword");
  return http({
    url: `${url}${id}/change_password/` ,
    method: 'PATCH',
    data
  })
}

export const httpInviteUser = (id) => {
  console.log("httpInviteUser");
  return http({
    url: `${url}${id}/send_invitation/` ,
    method: 'POST',
  })
}

export const httpGetTherapists = (params) => {
  console.log("httpGetUsers");
  return http({
    url: `/therapists/` ,
    method: 'GET',
    params,
  })
}

export const httpGetTherapist = (id) => {
  console.log("httpGetUsers");
  return http({
    url: `/therapists/${id}/` ,
    method: 'GET',

  })
}