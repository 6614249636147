import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import appConfig from "services/config";
import { setToken } from "services/token";
import { httpGetMyUser } from "services/users";

export const AuthContext = createContext({});

const AuthProviderComponent = ({ history, location, children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [myUser, setMyUser] = useState();
  
  const login = (accessToken, refreshToken) => {
    console.log("AuthContext:login");
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    getMyUser();
  }
  
  const logout = () => {
    console.log("AuthContext:logout");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    // window.location.reload();
    window.location.replace("/auth/login");
  }
  
  const getMyUser = (params) => {
    return new Promise((resolve, reject) => {
      
      if (appConfig?.TEST_NO_BACKEND === true) {
        setIsAuthenticated(true);
        setLoading(false);
        return resolve({id:1})
      }
      
      if (location?.pathname.startsWith("/auth/invitation")) {
        setLoading(false);
        reject()
        // return;
      }
      
      if (localStorage.getItem("accessToken") === null) {
        setIsAuthenticated(false);
        setLoading(false);
        reject()
      }
      else {
        setLoading(true);
        httpGetMyUser().then(response => {
          if (response && response.status === 200) {
            console.log("getMyUser response", response);
            setMyUser(response?.data);
            setIsAuthenticated(true);
            resolve(response?.data)
          }
          else {
            reject()
          }
        }, error => {
          setIsAuthenticated(false);
          reject()
        }).finally(res => {
          setLoading(false);
        })
      }
    })
  }
  
  useEffect(() => {
    getMyUser()
  }, [])
  
  if (loading) {
    return (
      <>Ladataan...</>
    )
  }
  
  return (
      <AuthContext.Provider
        value={{
          getMyUser,
          isAuthenticated,
          loading,
          logout,
          myUser,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
};
export const AuthProvider = withRouter(AuthProviderComponent);

AuthContext.defaultProps = {};
AuthContext.propTypes = {
  children: PropTypes.node.isRequired
};

export const useAuthContext = () =>
  useContext(AuthContext);
  
/*
# USAGE

import { useAuthContext } from "contexts/AuthContext"
const MyComponent = (props) => {
  const { myUser } = useAuthContext()
}
*/
